import Vue from 'vue'
// axios
import axios from 'axios'

//Vue Cookie
import VueCookies from 'vue-cookies'
Vue.prototype.$VueCookies = VueCookies
VueCookies.config('30d')


const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_mainUrl,
  timeout: 120000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
})
axiosIns.interceptors.request.use(function (config) {
  config.headers.Authorization = "Bearer " + Vue.prototype.$VueCookies.get("mastertube")
  return config;
});


axiosIns.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    Vue.prototype.$VueCookies.remove("mastertube");
    if (window.location.pathname!=="/login" && window.location.pathname!=="/support" && window.location.pathname!=="/signup") {
      window.location.href = "/login";
    }
    
  }
  return error;
});

Vue.prototype.$http = axiosIns

export default axiosIns
