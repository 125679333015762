import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import Hotjar from 'vue-hotjar'
import VueCookies from 'vue-cookies'
Vue.prototype.$VueCookies = VueCookies
VueCookies.config('30d')

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/toastification'
import '@/libs/portal-vue'

// import '@/libs/tour'
// import '@/libs/clipboard'

import '@/libs/sweet-alerts'

import '@/libs/axios.js'
import VueAnalytics from 'vue-analytics';

// Google Analytics 
Vue.use(VueAnalytics, {
  id: 'UA-177350711-1',
  router
})

Vue.use (Hotjar, {
  id: '3158598', // Hotjar Site ID
  isProduction: true,
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

//Count down Plugin
var vueAwesomeCountdown = require('vue-awesome-countdown').default;
Vue.use(vueAwesomeCountdown);

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

Vue.config.productionTip = false
Vue.prototype.mainurl = process.env.VUE_APP_mainUrl


Vue.mixin({
  methods: {
    makeToast: function (title, text, variant = null,time=5000) {
      this.$bvToast.toast(text, {
        title: title,
        variant,
        solid: true,
        autoHideDelay: time,
        toaster: 'b-toaster-top-full',
        toastClass:'w-75 mx-auto mastertube_toast'
      });
    },
  },
})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
