

export default {
  namespaced: true,
  state: {
   userNavs:[]
  },
  getters: {},
  mutations: {
    SET_USER_NAVS(state, UserNavs) {
        state.userNavs = UserNavs;
      },
  },

}
