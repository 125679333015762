import Vue from "vue";
import VueI18n from "vue-i18n";
import faLocale from './locales/fa.json'
import enLocale from './locales/en.json'
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "fa",
  fallbackLocale: "fa",
  messages: {
    fa: faLocale,
    en: enLocale,
  },
});

export default i18n;
