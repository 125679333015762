import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '../libs/axios.js'

Vue.use(VueRouter)

const Dashboard = () => import('@/views/dashboard/Dashboard.vue')
const Pricing = () => import('@/views/plan/pricing.vue')
const Accounting = () => import('@/views/accounting/accounting.vue')

const PricingBack = () => import('@/views/plan/upgrade.vue')
const addChannel = () => import('@/views/channel/addChannel.vue')
const addChannelWordpress = () => import('@/views/channel/addChannelWordpress.vue')

const Channels = () => import('@/views/channel/channels.vue')
const AccountingChannel = () => import('@/views/channel/accounting-details.vue')
const ReferralDetalis = () => import('@/views/channel/referral-detalis.vue')
const StudioDashboard = () => import('@/views/channel/studio/Dashboard.vue')
const Playlists = () => import('@/views/channel/studio/playlists.vue')
const Videos = () => import('@/views/channel/studio/videos.vue')
const ReviewCheck = () => import('@/views/review/check.vue')
const Notifications = () => import('@/views/notification/List.vue')
const Setting = () => import('@/views/account/Setting.vue')
const SendReport = () => import('@/views/report/send-report.vue')
const City = () => import('@/views/city/list.vue')
const CityChannel = () => import('@/views/city/channel.vue')
const Shop = () => import('@/views/shop/list.vue')
const ShopInfo = () => import('@/views/shop/info.vue')
const ShopBack = () => import('@/views/shop/back.vue')
const ShopPurchased = () => import('@/views/shop/payed.vue')
const MasterFile = () => import('@/views/master-file/List.vue')
const MasterFileInfo = () => import('@/views/master-file/info.vue')
const MentorReview = () => import('@/views/mentor/channels.vue')
const AssistantReviews = () => import('@/views/assistant/reviews.vue')
const AssistantNewReviews = () => import('@/views/assistant/new-reviews.vue')
const AssistantChannelAnalytics = () => import('@/views/assistant/channel-analytics.vue')
const AssistantVideoAnalytics = () => import('@/views/assistant/video-analytics.vue')
const AssistantneedToCheck = () => import('@/views/assistant/need-to-check.vue')
const Login = () => import('@/views/auth/Login.vue')
const ForgetPassword = () => import('@/views/auth/ForgetPassword.vue')
const ResetPassword = () => import('@/views/auth/ResetPassword.vue')
const LoginCallback = () => import('@/views/auth/LoginCallback.vue')
const SignUp = () => import('@/views/auth/SignUp.vue')
const Error404 = () => import('@/views/error/Error404.vue')
const Ticket = () => import('@/views/ticket/Tickets.vue')
const TicketNew = () => import('@/views/ticket/NewTicket.vue')
const Support = () => import('@/views/support/Support.vue')
const Comments = () => import('@/views/channel/studio/comments.vue')
const Calculator = () => import('@/views/tools/Calculator.vue')
const ThumbnailDownloader = () => import('@/views/tools/ThumbnailDownloader.vue')
const SubscribeLinkGenerator = () => import('@/views/tools/SubscribeLinkGenerator.vue')
const DefaultUploadProfile = () => import('@/views/channel/studio/DefaultUploadProfile.vue')
const ChannelBackup = () => import('@/views/channel/studio/ChannelBackup.vue');
const HealthReport = () => import('@/views/channel/studio/HealthReport.vue');
const youtubeAccounts=process.env.VUE_APP_YOUTUBE_ACCOUNTS;
const youtubeAccountsArray=youtubeAccounts?.split(',');
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        pageTitle: 'Page_Title.Dashboard',
        authRequired: true,
        showDate: true,
      },
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing,
      meta: {
        pageTitle: 'Page_Title.Upgrade User Account',
        authRequired: true,
      },
    },
    {
      path: '/accounting',
      name: 'Accounting',
      component: Accounting,
      meta: {
        pageTitle: 'Page_Title.Accounting',
        authRequired: true,
      },
    },
    {
      path: '/master-coin',
      name: 'MasterCoin',
      component: () => import('@/views/masterCoin/list.vue'),
      meta: {
        pageTitle: 'Page_Title.Buy Master Coin',
        authRequired: true,
      },
    },
    {
      path: '/master-coin/pay/back',
      name: 'MasterCoinpayback',
      component: () => import('@/views/masterCoin/back.vue'),
      meta: {
        pageTitle: 'Page_Title.Buy Master Coin',
        authRequired: true,
      },
    },
    {
      path: '/pricing/pay/back',
      name: 'PricingBack',
      component: PricingBack,
      meta: {
        pageTitle: 'Page_Title.Callback Upgrade User Account',
        authRequired: true,
      },
    },
    {
      path: '/channel/add/back',
      name: 'addchannel',
      component: addChannel,
      meta: {
        pageTitle: 'Page_Title.Callback Add Channel',
        authRequired: true,
      },
    },
    {
      path: '/wordpress/channel/add/back',
      name: 'addChannelWordpress',
      component: addChannelWordpress,
      meta: {
        pageTitle: 'Page_Title.Callback Add Channel',
      },
    },
    {
      path: '/channels',
      name: 'channels',
      component: Channels,
      meta: {
        pageTitle: 'Page_Title.Channels List',
        addChannel: true,
        authRequired: true,
      },
    },
    {
      path: '/channel/accounting',
      name: 'accounting',
      component: Accounting,
      meta: {
        pageTitle: 'Page_Title.Channels Accounting',
        showDate: true,
        authRequired: true,
      },
    },
    {
      path: '/channel/accounting/details',
      name: 'accounting-channel',
      component: AccountingChannel,
      meta: {
        pageTitle: 'Page_Title.Channels Accounting Details',
        authRequired: true,
        showDate: true,
      },
    },
    {
      path: '/channel/accounting/referral/details',
      name: 'referral-detalis',
      component: ReferralDetalis,
      meta: {
        pageTitle: 'Page_Title.Details Referral Channel',
        authRequired: true,
        showDate: true,
      },
    },
    {
      path: '/channel/:channel_id/studio/dashboard',
      name: 'studioDashboard',
      component: StudioDashboard,
      meta: {
        pageTitle: 'Page_Title.Channel Dashboard',
        layout: 'studio',
        showDate: true,
        authRequired: true,
        video: true
      },
    },
    {
      path: '/channel/:channel_id/studio/playlists',
      name: 'playlists',
      component: Playlists,
      meta: {
        pageTitle: 'Page_Title.Playlists Channel',
        layout: 'studio',
        authRequired: true,
        playlist: true
      },
    },
    {
      path: '/channel/:channel_id/studio/videos',
      name: 'videos',
      component: Videos,
      meta: {
        pageTitle: 'Page_Title.Videos Channel',
        layout: 'studio',
        authRequired: true,
        video: true
      },
    },
    {
        path: '/channel/:channel_id/studio/comments',
        name: 'comments',
        component: Comments,
          meta: {
            pageTitle: 'Page_Title.Comments',
            authRequired: true,
            layout: 'studio',
            showDate: true
          }
     },
    {
      path: '/channel/:channel_id/studio/health-report',
      name: 'healthReport',
      component: HealthReport,
      meta: {
        pageTitle: 'Page_Title.Channel Health Report',
        layout: 'studio',
        authRequired: true,
      },
    },
    {
      path: '/review/check',
      name: 'reviewCheck',
      component: ReviewCheck,
      meta: {
        pageTitle: 'Page_Title.Review Request',
        authRequired: true,
      },
    },
    {
      path: '/notification/list',
      name: 'notifications',
      component: Notifications,
      meta: {
        pageTitle: 'Page_Title.Notifications',
        authRequired: true,
      },
    },
    {
      path: '/account/setting',
      name: 'setting',
      component: Setting,
      meta: {
        pageTitle: 'Page_Title.Setting',
        authRequired: true,
      },
    },
    {
      path: '/support/ticket',
      name: 'ticket',
      component: Ticket,
      meta: {
        authRequired: true,
        pageTitle: 'Page_Title.Ticket',
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        btnNewTicket:true
      },
    },
    {
      path: '/support/ticket/new',
      name: 'ticket-new',
      component: TicketNew,
      meta: {
        pageTitle: 'Page_Title.Send Ticket',
        authRequired: true,
      },
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
      meta: {
        pageTitle: 'Page_Title.Send Ticket',
        layout: 'full',
      },
    },
 
    {
      path: '/send-report',
      name: 'send-report',
      component: SendReport,
      meta: {
        pageTitle: 'Page_Title.Send Report',
        authRequired: true,
      },
    },
    {
      path: '/city',
      name: 'city',
      component: City,
      meta: {
        pageTitle: 'Page_Title.Mastertube City',
        authRequired: true,
        addRequestToCity: true
      },
    },
    {
      path: '/city/channel',
      name: 'cityChannel',
      component: CityChannel,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/shop',
      name: 'shop',
      component: Shop,
      meta: {
        pageTitle: 'Page_Title.Store',
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        authRequired: true,
      },
    },
    {
      path: '/shop/info',
      name: 'shopInfo',
      component: ShopInfo,
      meta: {
        pageTitle: 'Page_Title.Product Info',
        contentClass: 'ecommerce-application',
        authRequired: true,
      },
    },
    {
      path: '/shop/pay/back',
      name: 'shopBack',
      component: ShopBack,
      meta: {
        pageTitle: 'Page_Title.Callbank Purchase Products',
        authRequired: true,
      },
    },
    {
      path: '/shop/purchased',
      name: 'shopPurchased',
      component: ShopPurchased,
      meta: {
        pageTitle: 'Page_Title.Shop Purchased',
        contentClass: 'ecommerce-application',
        authRequired: true,
      },
    },
    {
      path: '/master-file',
      name: 'masterFile',
      component: MasterFile,
      meta: {
        pageTitle: 'Page_Title.MasterFile',
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        authRequired: true,
      },
    },
    {
      path: '/master-file/file',
      name: 'masterFileInfo',
      component: MasterFileInfo,
      meta: {
        pageTitle: 'Page_Title.MasterFile',
        authRequired: true,
      },
    },
    {
      path: '/mentor/channels',
      name: 'mentor-reviews',
      component: MentorReview,
      meta: {
        pageTitle: 'Page_Title.Mentor Request Reviews',
        authRequired: true,
      },
    },
    {
      path: '/tools/assistant/reviews',
      name: 'assistant-reviews',
      component: AssistantReviews,
      meta: {
        pageTitle: 'Page_Title.Assistant Reviews',
        authRequired: true,
      },
    },
    {
      path: '/tools/assistant/reviews/new',
      name: 'assistant-reviews-new',
      component: AssistantNewReviews,
      meta: {
        pageTitle: 'Page_Title.Assistant Reviews New',
        authRequired: true,
      },
    },
    {
      path: '/tools/assistant/:channel_id/:uuid/channel',
      name: 'Assistant',
      component: AssistantChannelAnalytics,
      meta: {
        pageTitle: 'Page_Title.Analysis Channel',
        layout: 'assistant',
        authRequired: true,
      },
    },
    {
      path: '/tools/assistant/:channel_id/:uuid/video',
      name: 'Analysis-video',
      component: AssistantVideoAnalytics,
      meta: {
        pageTitle: 'Page_Title.Analysis Video',
        layout: 'assistant',
        authRequired: true,
      },
    },
    {
      path: '/tools/assistant/:channel_id/:uuid/need-to-check',
      name: 'need-to-check',
      component: AssistantneedToCheck,
      meta: {
        pageTitle: 'Page_Title.Need To Check',
        layout: 'assistant',
        authRequired: true,
        skipCheckPermission: true
      },
    },
    {
      path: '/tools/calculator',
      name: 'calculator',
      component: Calculator,
      meta: {
        pageTitle: 'Page_Title.Calculator',
        authRequired: true,
      }
    },
    {
      path: '/tools/thumbnail-downloader',
      name: 'thumbnaildownloader',
      component: ThumbnailDownloader,
      meta: {
        pageTitle: 'Page_Title.Thumbnail Downloader',
        authRequired: true,
      },
    },
    {
      path: '/channel/:channel_id/studio/uploadProfile',
      name: 'defaultUploadProfile',
      component: DefaultUploadProfile,
      meta: {
        pageTitle: 'Page_Title.Default Upload Profile',
        layout: 'studio',
        authRequired: true,
        showDefaultUploadProfileModal: true

        }
    },{
    path: '/tools/subscribe-link-generator',
      name: 'subscribeLinkGenerator',
      component: SubscribeLinkGenerator,
      meta: {
        pageTitle: 'Page_Title.Subscribe Link Generator',
        authRequired: true,
      }
    },
    {
      path: '/channel/:channel_id/studio/backup',
      name: 'channelBackup',
      component: ChannelBackup,
      meta: {
        pageTitle: 'Page_Title.Channel Backup',
        layout: 'studio',
        showDate: true,
        authRequired: true
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgetPassword',
      name: 'forgetPassword',
      component: ForgetPassword,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: ResetPassword,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login/callback',
      name: 'loginCallback',
      component: LoginCallback,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: Error404,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.permission) {
    axios.get('/api/check_login').then(async ({ data }) => {
      let permissions = [];
      data.permissions.map((item) => {
        permissions.push(item.name);
      });
      if (!permissions.includes(to.meta.permission)) {
        next({
          path: "/pricing",
        });
      }
    })
      .catch((error) => {
        next({
          path: "/pricing",
        });
      });
  } else if (to.meta.authRequired) {
    axios.get('api/check_login').then(resp => {
      const userEmail=resp.data.user.email;
      if (youtubeAccountsArray.includes(userEmail) && to.name === 'Accounting') {
        next({
          path: "/error-404",
        });
       }

    }).catch(err => {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
    })
  }

  if (to.name == 'masterFile' && from.name == 'masterFileInfo') {
    to.meta.use_history = true;
  }
  return next();
})
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router